import React, { useRef, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ContactSuccess from "../components/contact-success"
import SEO from "../components/seo"
import API from "../constants/api"
import ReCAPTCHA from "react-google-recaptcha"
import "../contact.css"

const ReportContent = ({ data }) => {
  const issues = [
    { issue: "Infringes my rights or other legal concern", value: "infringe" },
    { issue: "Copyright concern", value: "copyright" },
    { issue: "Potentially features a minor", value: "minor" },
    { issue: "Violent or harmful acts", value: "violent" },
    { issue: "Hateful or inflammatory", value: "hateful" },
    { issue: "Spam or misleading content", value: "spam" },
    { issue: "Otherwise inappropriate or objectionable", value: "other" },
  ]
  const sites = data.allMysqlSites.edges
    .filter(node => node.node.is_active === "y")
    .sort((a, b) => {
      let x = a.node.site_name[0].toLowerCase()
      let y = b.node.site_name[0].toLowerCase()
      if (x < y) {
        return -1
      }
      if (x > y) {
        return 1
      }
      return 0
    })

  const recaptchaRef = useRef(null)
  const finalValues = useRef(null)
  const [addInfo, setAddInfo] = useState("")
  const [issue, setIssue] = useState("")
  const [isProcessing, setIsProcessing] = useState(false)
  const [msgSent, setMsgSent] = useState(false)
  const [serverError, setServerError] = useState("")
  const [errType, setErrType] = useState("")
  const [url, setURL] = useState("")
  const [email, setEmail] = useState("")
  const [digital, setDigital] = useState("")
  const [friend, setFriend] = useState("")
  const [legal, setLegal] = useState("")
  const handleSubmit = e => {
    e.preventDefault()

    const vali = validateForm()
    if (vali) {
      setIsProcessing(true)
      setErrType("")
      finalValues.current = {
        email: email,
        friend: friend,
        url: url,
        digital: digital,
        legal: legal,
        addInfo: addInfo,
        issue: issue,
      }
      recaptchaRef.current.execute()
    }
  }
  const validateForm = () => {
    if (email === "" || !email.includes("@") || !email.includes(".")) {
      setErrType("email")
      return false
    }

    if (digital === "") {
      setErrType("digital")
      return false
    }
    if (legal === "") {
      setErrType("legal")
      return false
    }
    if (url === "") {
      setErrType("url")
      return false
    }

    if (issue === "") {
      setErrType("issue")
      return false
    }

    if (friend === "") {
      setErrType("friend")
      return false
    }

    if (legal !== digital) {
      setErrType("matchError")
      return false
    }

    return true
  }
  const formSuccess = () => {
    setMsgSent(true)
  }
  const fromHasError = specialErrMsg => {
    setServerError(specialErrMsg)
  }
  const handleFormSubmit = tok => {
    let data = new FormData()
    data.append("email", finalValues.current.email)
    data.append("friend", finalValues.current.friend)
    data.append("url", finalValues.current.url)
    data.append("digital", finalValues.current.digital)
    data.append("legal", finalValues.current.legal)
    data.append("addInfo", finalValues.current.addInfo)
    data.append("issue", finalValues.current.issue)
    data.append("token", tok)
    fetch(`${API.SUPPORT}get_help_rc.php`, {
      method: "POST",
      body: data,
      headers: {
        "X-XSRF-Header": API.XSRF,
      },
    })
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          formSuccess()
        } else {
          fromHasError(res.error)
        }
      })
  }
  return (
    <Layout>
      <SEO title="Contact Us" />
      <div className="section_form report">
        <div className="section_background report"></div>
        <div className="block_form">
          <form action="#">
            <div className="block_form_wrapper">
              {msgSent ? (
                <ContactSuccess />
              ) : (
                <>
                  <div className="heading">
                    <h2 className="title">
                      Report <strong>Content</strong>
                    </h2>
                    <p>
                      If you see something inappropriate, please report it. Your
                      reports help us protect both users and performers on our
                      platform. We appreciate your feedback and commitment to
                      building a positive community. Reporting is completely
                      confidential. We will review your report{" "}
                      <strong>within 7 days</strong> and take appropriate
                      action.
                      {serverError !== "" ? (
                        <div className="errorServer">
                          ERROR: {serverError}, Please contact us directly{" "}
                          <a href="mailto:service@addfriends.com">
                            service@addfriends.com
                          </a>
                        </div>
                      ) : null}
                    </p>
                  </div>
                  {serverError !== "" ? null : (
                    <div className="field">
                      <div className="row">
                        <div className="wrap">
                          <label htmlFor="message" className="label">
                            Issue With Content?{" "}
                            <span className="required">*</span>
                          </label>
                          <select
                            className={
                              errType === "issue"
                                ? "afSelect wError"
                                : "afSelect"
                            }
                            onChange={e => {
                              setIssue(e.target.value)
                            }}
                          >
                            <option value="">PICK ONE</option>
                            {issues.map(issue => (
                              <option
                                value={issue.value}
                                key={`=issue-${issue.value}`}
                              >
                                {issue.issue}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="wrap">
                          <label htmlFor="email" className="label">
                            Email <span className="required">*</span>
                          </label>
                          <input
                            type="email"
                            id="email"
                            className={
                              errType === "email" ? "input error" : "input"
                            }
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                          />
                          {errType === "email" ? (
                            <div className="errorMsg">Error</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="wrap">
                          <label htmlFor="url" className="label">
                            URL of content you are reporting{" "}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            id="url"
                            className={
                              errType === "url" ? "input error" : "input"
                            }
                            value={url}
                            onChange={e => setURL(e.target.value)}
                          />
                          {errType === "url" ? (
                            <div className="errorMsg">Error</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="wrap">
                          <label htmlFor="legal" className="label">
                            Legal name <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            id="legal"
                            className={
                              errType === "legal" ? "input error" : "input"
                            }
                            value={legal}
                            onChange={e => setLegal(e.target.value)}
                          />
                          {errType === "legal" ? (
                            <div className="errorMsg">Error</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="wrap">
                          <label htmlFor="addInfo" className="label">
                            Additional Information <i>(optional)</i>
                          </label>
                          <textarea
                            name="addInfo"
                            id="addInfo"
                            cols="30"
                            rows="10"
                            className="textarea"
                            onChange={e => setAddInfo(e.target.value)}
                            value={addInfo}
                          ></textarea>
                        </div>
                      </div>
                      <div className="row">
                        <div className="wrap">
                          <label htmlFor="message" className="label">
                            What Friend? <span className="required">*</span>
                          </label>
                          <select
                            className={
                              errType === "friend"
                                ? "afSelect wError"
                                : "afSelect"
                            }
                            onChange={e => {
                              setFriend(e.target.value)
                            }}
                          >
                            <option value="">PICK ONE</option>
                            {sites.map(site => (
                              <option
                                value={site.node.site_name}
                                key={`cu${site.node.mysqlId}`}
                              >
                                {site.node.site_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="wrap">
                          <label htmlFor="digital" className="label">
                            Digital Signature{" "}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            id="digital"
                            className={
                              errType === "digital" ? "input error" : "input"
                            }
                            value={digital}
                            onChange={e => setDigital(e.target.value)}
                          />
                          {errType === "digital" ? (
                            <div className="errorMsg">Error</div>
                          ) : null}
                          {errType === "matchError" ? (
                            <div className="errorMsg">
                              Digital signature and full name need to match!
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="row">
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          onChange={e => handleFormSubmit(e, email, friend)}
                          theme="dark"
                          badge="inline"
                          size="invisible"
                          sitekey="6LdFnKgUAAAAAMMrHjMOzd42QrbFzhO-aO_Rme2k"
                        />
                      </div>

                      <div className="row">
                        <button
                          className={
                            isProcessing
                              ? "button_more processing"
                              : "button_more"
                          }
                          onClick={handleSubmit}
                        >
                          Submit
                          <div className="nk-spinner"></div>
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuerySucc = graphql`
  query ReportQuery {
    allMysqlSites {
      edges {
        node {
          free_snapchat
          site_name
          is_active
          site_url
          mysqlId
          sitestat {
            likes
            videos
          }
        }
      }
    }
  }
`

export default ReportContent
